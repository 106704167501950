import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <link
          href="/themes/simple/css/owl.carousel.min.css?t=1"
          type="text/css"
          rel="stylesheet"
        />
        <link
          href="/themes/simple/css/skin.css?t=1"
          type="text/css"
          rel="stylesheet"
        />
        <div className="boper boper-hb">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a
                    href="/site/skora-z-niedoskonalosciami/"
                    className="current"
                  >
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
          </div>
          <section
            className="skin"
            style={{
              "padding-bottom": "80px"
            }}
          >
            <div className="skin__top">
              <div className="content banner">
                <h1 className="skin__title">
                  Zdrowa skóra <br />
                  <strong>to #wyzwanie?</strong>
                </h1>
                Nie. Jeśli odpowiednio o nią dbasz.
                <br />
                Znajdź swój patent na zdrową <br />
                pielęgnajcę każdego dnia
                <br /> z Neutrogena<sup>®</sup> <strong>Visibly Clear™!</strong>
              </div>
              <div className="content question">
                <h3 className="skin__title">
                  <strong>Pytanie</strong>
                </h3>
                Odpowiedz na 5 pytań dotyczących Twojej
                <br /> skóry i przyzwyczajeń, a podpowiemy Ci, <br />
                których kosmetyków pownieneś spróbować.
                <br />
                <br />
                <p className="tx_right">Rozpocznij test...</p>
              </div>
              <div className="content quiz">
                <div className="quest__window">
                  <div className="quest active" data-quest="1">
                    <p className="skin__title tx_center">
                      <strong>Czy Twoim zdaniem</strong>
                      <br /> Twoja skóra jest:
                    </p>
                    <div className="answer">
                      <div
                        className="answer__item answer__item-2"
                        data-id="[1,2]"
                        style={{
                          "-webkit-align-items": "flex-start",
                          "-webkit-box-align": "flex-start",
                          "-ms-flex-align": "flex-start",
                          "align-items": "flex-start"
                        }}
                      >
                        <div>
                          <strong>Tłusta</strong>
                          <br />
                          - zwiększona produkcja sebum, stany zapalne, pryszcze
                          <br />- zaskórniki, rogowacenie skory, głównie w
                          okolicy nosa
                        </div>
                      </div>
                      <div
                        className="answer__item answer__item-2"
                        data-id="[2,3]"
                        style={{
                          "-webkit-align-items": "flex-start",
                          "-webkit-box-align": "flex-start",
                          "-ms-flex-align": "flex-start",
                          "align-items": "flex-start"
                        }}
                      >
                        <div>
                          <strong>Sucha</strong>
                          <br />
                          - gładka bez zaskórników
                          <br />- nie sprawia problemów
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="quest" data-quest="2">
                    <p className="skin__title tx_center">
                      <strong>Problem,</strong>
                      <br /> który zauważasz u siebie:
                    </p>
                    <div className="answer">
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[3]"
                      >
                        <span>
                          Moja twarz jest płytko unaczyniona, widać
                          zaczerwienienia i naczynka
                        </span>
                      </div>
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[2]"
                      >
                        <span>
                          Moja skóra jest zupełnie bezproblemowa choć szybko się
                          przesusza
                        </span>
                      </div>
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[1]"
                      >
                        <span>Dokuczają mi pryszcze i zaskórniki</span>
                      </div>
                    </div>
                  </div>
                  <div className="quest" data-quest="3">
                    <p className="skin__title tx_center">
                      <strong>Jak często </strong>
                      <br /> używasz kosmetyków:
                    </p>
                    <div className="answer">
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[2]"
                      >
                        <span>Nie używam, ale chciałabym zacząć</span>
                      </div>
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[1,2,3]"
                      >
                        <span>Normalnie, rano i wieczorem</span>
                      </div>
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[1,3]"
                      >
                        <span>Straciłam rachubę</span>
                      </div>
                    </div>
                  </div>
                  <div className="quest" data-quest="4">
                    <p className="skin__title tx_center">
                      <strong>Twój ulubiony </strong>
                      <br />
                      typ kosmetyku:
                    </p>
                    <div className="answer">
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[1]"
                      >
                        <span>Żel do mycia twarzy</span>
                      </div>
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[3]"
                      >
                        <span>Wszelkiego typu maseczki</span>
                      </div>
                      <div
                        className="answer__item answer__item-3 tx_center"
                        data-id="[2]"
                      >
                        <span>Peeling</span>
                      </div>
                    </div>
                  </div>
                  <div className="quest" data-quest="5">
                    <p className="skin__title tx_center">
                      <strong>Jesteś:</strong>
                      <br /> &nbsp;
                    </p>
                    <div className="answer">
                      <div
                        className="answer__item answer__item-2 tx_center"
                        data-id="[1,2]"
                      >
                        <span>mężczyzną</span>
                      </div>
                      <div
                        className="answer__item answer__item-2 tx_center"
                        data-id="[1,2,3]"
                      >
                        <span>kobietą</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="quest__result">
                  <p className="name">Polecamy Ci:</p>
                  <p className="subname">
                    Zobacz
                    <br />
                    także:
                  </p>
                  <div className="elements">
                    <a href="/site/product/58/" className="main main-spot">
                      <img src="/themes/simple/img/skin/result_1_1.png" />
                    </a>
                    <a href="/site/product/46/" className="main main-pink">
                      <img src="/themes/simple/img/skin/result_1_2.png" />
                    </a>
                    <a href="/site/product/53/" className="main main-pore">
                      <img src="/themes/simple/img/skin/result_1_3.png" />
                    </a>
                    <a href="/site/product/58/" className="second second-spot">
                      <img src="/themes/simple/img/skin/result_2_1.png" />
                    </a>
                    <a href="/site/product/46/" className="second second-pink">
                      <img src="/themes/simple/img/skin/result_2_2.png" />
                    </a>
                    <a href="/site/product/53/" className="second second-pore">
                      <img src="/themes/simple/img/skin/result_2_3.png" />
                    </a>
                  </div>
                </div>
                <div className="quest__nav">
                  <span className="quest__nav__item sel" data-quest="1" />
                  <span className="quest__nav__item" data-quest="2" />
                  <span className="quest__nav__item" data-quest="3" />
                  <span className="quest__nav__item" data-quest="4" />
                  <span className="quest__nav__item" data-quest="5" />
                  <img
                    src="/themes/simple/img/skin/btn_next.png"
                    alt="Dalej"
                    className="btn btn-next disabled"
                  />
                </div>
              </div>
              <div className="content challenge">
                <h2 className="skin__title">
                  <strong>Wyzwanie</strong>
                </h2>
                Wszyscy podejmujemy wyzwania. <br />
                Każdego dnia. Nie chcemy składać
                <br />
                Ci obietnic bez pokrycia. Nie będziemy
                <br />
                też lać wody o naszych kosmetykach.
                <br />
                Przygotuj się na konkretne informacje
                <br />
                i&nbsp;konkretne efekty.
              </div>
            </div>
            <div className="skin__products">
              <div className="product product-1">
                <div className="bg" />
                <div className="content">
                  <div className="item">
                    <h3 className="name">Spot proofing</h3>
                    4 kroki do lepszej skóry! <br />
                    Dbaj o nią codziennie, rano i wieczorem.
                    <div
                      id="skin_product_slider"
                      className="slider owl-carousel skin_product_slider orange"
                    >
                      <div className="slider__item" data-img="packshot-1-1">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/1_orange.png"
                            alt="1"
                          />
                        </div>
                        <div className="desc">
                          <strong>Umyj</strong>
                          <br />
                          Żelem, który oczyszcza skórę oraz redukuje wypryski
                          już od pierwszego użycia. Nie narusza bariery
                          ochronnej, aby skóra była bardziej odporna na
                          powstawanie nowych wyprysków.
                        </div>
                      </div>
                      <div className="slider__item" data-img="packshot-1-2">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/2_orange.png"
                            alt="2"
                          />
                        </div>
                        <div className="desc">
                          <strong>Oczyść</strong>
                          <br />
                          Wygładzającym peelingiem, który dogłębnie oczyszcza
                          pory, pomagając redukować istniejące wypryski.
                        </div>
                      </div>
                      <div className="slider__item" data-img="packshot-1-3">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/3_orange.png"
                            alt="3"
                          />
                        </div>
                        <div className="desc">
                          <strong>Nawilż</strong>
                          <br />
                          Lekkim, nie zatykającym porów kremem, który nada
                          skórze miękkości. Technologia ClearDefend™ pomaga
                          redukować istniejące wypryski.
                        </div>
                      </div>
                      <div className="slider__item" data-img="packshot-1-4">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/4_orange.png"
                            alt="4"
                          />
                        </div>
                        <div className="desc">
                          <strong>Chroń</strong>
                          <br />
                          Żelem myjącym i maską do twarzy 2 w 1, która uodparnia
                          skórę na powstawanie nowych wyprysków.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={{
                      height: "460px"
                    }}
                  >
                    <div id="packshot-1-1" className="packshoot visible">
                      <img
                        src="/themes/simple/img/skin/packshot_spot_proofing.png"
                        alt="spot proofing"
                      />
                    </div>
                    <div
                      id="packshot-1-2"
                      className="packshoot"
                      style={{
                        padding: "52px 0 0 17px"
                      }}
                    >
                      <img
                        src="/themes/simple/img/skin/packshot_spot_proofing-2b.png"
                        alt="spot proofing"
                      />
                    </div>
                    <div
                      id="packshot-1-3"
                      className="packshoot"
                      style={{
                        padding: "60px 0 0 30px"
                      }}
                    >
                      <img
                        src="/themes/simple/img/skin/packshot_spot_proofing-3.png"
                        alt="spot proofing"
                      />
                    </div>
                    <div
                      id="packshot-1-4"
                      className="packshoot"
                      style={{
                        padding: "51px 0 0 18px"
                      }}
                    >
                      <img
                        src="/themes/simple/img/skin/packshot_spot_proofing-4.png"
                        alt="spot proofing"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="product product-2">
                <div className="bg" />
                <div className="content">
                  <div
                    className="item"
                    style={{
                      height: "460px"
                    }}
                  >
                    <div
                      id="packshot-2-1"
                      className="packshoot visible"
                      style={{
                        left: "25px",
                        top: "-42px"
                      }}
                    >
                      <img
                        src="/themes/simple/img/skin/packshot_pink_grapefruit-1b.png"
                        alt="pink rapefruit"
                      />
                    </div>
                    <div
                      id="packshot-2-2"
                      className="packshoot"
                      style={{
                        left: "23px",
                        top: "-5px"
                      }}
                    >
                      <img
                        src="/themes/simple/img/skin/packshot_pink_grapefruit-2b.png"
                        alt="pink rapefruit"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <h3 className="name">Pink grapefruit</h3>
                    2 kroki do lepszej skóry!
                    <br /> Dbaj o nią codziennie, rano i wieczorem.
                    <div
                      id="skin_product_slider"
                      className="slider owl-carousel skin_product_slider pink"
                    >
                      <div className="slider__item" data-img="packshot-2-1">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/1_pink.png"
                            alt="1"
                          />
                        </div>
                        <div className="desc">
                          <strong>Umyj</strong>
                          <br />
                          Orzeźwiającym żelem, który nada twojej skórze świeżego
                          i zdrowego wyglądu. Formuła z technologią MicroClear
                          <sup>®</sup> głęboko oczyszcza i pomaga eliminować
                          pryszcze i wągry.
                        </div>
                      </div>
                      <div className="slider__item" data-img="packshot-2-2">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/2_pink.png"
                            alt="2"
                          />
                        </div>
                        <div className="desc">
                          <strong>Oczyść</strong>
                          <br />
                          Pobudzającym zmysły peelingiem, który łagodnie
                          oczyszcza skórę bez jej wysuszania. Technologia
                          MicroClear<sup>®</sup> i złuszczające mikrocząsteczki
                          tworzą formułę, która odblokowuje pory, głęboko
                          oczyszcza skórę i pomaga eliminować pryszcze i wągry.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product product-3">
                <div className="bg" />
                <div className="content">
                  <div className="item">
                    <h3 className="name">Pore and shine daily wash</h3>2 kroki
                    do lepszej skóry!
                    <div
                      id="skin_product_slider"
                      className="slider owl-carousel skin_product_slider green"
                    >
                      <div className="slider__item" data-img="packshot-3-1">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/1_green.png"
                            alt="1"
                          />
                        </div>
                        <div className="desc">
                          <strong>Umyj</strong>
                          <br />
                          Odblokowującym pory żelem dla wyraźnego polepszenia
                          jej wyglądu. Natychmiast usuwa 77%* nadmiaru sebum i
                          zapewnia długotrwały efekt matujący bez przesuszania
                          skóry.
                          <br />
                          <p className="small">
                            * pomiar ilości sebum natychmiast po zastosowaniu,
                            wartość średnia dla 16 badanych.
                          </p>
                        </div>
                      </div>
                      <div className="slider__item" data-img="packshot-3-2">
                        <div className="number">
                          <img
                            src="/themes/simple/img/skin/2_green.png"
                            alt="2"
                          />
                        </div>
                        <div className="desc">
                          <strong>Oczyść</strong>
                          <br />
                          Zwężającym pory i matującym peelingiem, który głębo
                          oczyszcza skórę dla wyraźnego polepszenia jej wyglądu.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={{
                      height: "460px"
                    }}
                  >
                    <div
                      id="packshot-3-1"
                      className="packshoot visible"
                      style={{
                        top: "-20px",
                        left: "20px"
                      }}
                    >
                      <img
                        src="/themes/simple/img/skin/packshot_pore_shine-1b.png"
                        alt="pore&shine"
                      />
                    </div>
                    <div
                      id="packshot-3-2"
                      className="packshoot"
                      style={{
                        top: "-20px",
                        left: "16px"
                      }}
                    >
                      <img
                        src="/themes/simple/img/skin/packshot_pore_shine-3.png"
                        alt="pore&shine"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <a
                href=" https://www.rossmann.pl/produkty?Search=neutrogena%20visibly%20clear&Page=1&PageSize=12&PriceFrom=0&PriceTo=600"
                className="rossmann"
                target="_blank"
              >
                <img
                  src="/themes/simple/img/skin/rossmann_all.png"
                  alt="kup w rossmann"
                />
              </a>
            </div>
          </section>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
              <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
              </nav>
              <a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z
                o. o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 25/10/2013
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script  type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
        <script src="/site/themes/simple/js/owl.carousel.min.js" />
        <script src="/site/themes/simple/js/slide_owl.js" />
        <script src="/site/themes/simple/js/quiz.js" />
      </div>
    );
  }
}

export default Page;
